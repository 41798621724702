/*
 * @Author: Tiger
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2022-09-10 19:32:36
 * @LastEditors: qiufh qiufh@bocspace.cn
 * @Description: About Us
 * @FilePath: /official_website/src/pages/about.js
 */ 
import React, { useEffect, useState } from 'react'
import url from 'url'
import Layout from '../components/layout'

const AboutUsPage = () => {
  const [typeFlag, setTypeFlag] = useState('companies');

  useEffect(()=>{
      let search = window.location.href
  const type = url.parse(search, true).query.type;
      console.log('type', type)
      setTypeFlag(type);
  }, [typeof window !== 'undefined' ? window.location.href : ''])

  return (
    <Layout>
        {
            typeFlag == 'companies' ? 
            <div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/companies1.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/companies2.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/companies3.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/companies4.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/companies5.png" />
                </div>
            </div> : 
            typeFlag == 'factory' ? 
            <div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory1.png" />
                </div>
                {/* <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory2.png" />
                </div> */}
                <div className="factory-video">
                    <div className="factory-title">
                        BOCSPACE Cloud Factory
                    </div>
                    <div className="factory-video-box">
                        <video src="/about/factory.mp4" controls="controls"></video>
                        <img className="video-text" src="/about/factory21.png" />
                    </div>
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory22.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory3.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory4.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory5.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory6.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/factory7.png" />
                </div>
            </div> : 
            typeFlag == 'honor' ? 
            <div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/honor1.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/honor2.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/honor3.png" />
                </div>
                <div className="bocsapce-introduce" >
                    <img className="content-img" src="/about/honor4.png" />
                </div>
            </div> : 
            null
        }
    </Layout>

  )
}

export default AboutUsPage
